<template>
  <g :fill="color" fill-rule="evenodd">
    <path
      fill-rule="nonzero"
      d="M15.75 6.724c0 .55-.282 1.23-.66 1.606l-6.746 7.004a1.386 1.386 0 0 1-1.96.001L.657 9.612a1.388 1.388 0 0 1 .01-1.97L7.655.922C8.043.531 8.723.25 9.272.25h5.091c.765 0 1.387.622 1.387 1.387v5.087zm-1.73.554a1.06 1.06 0 0 0 .23-.554V1.75H9.272c-.15 0-.448.124-.565.241L1.799 8.633l5.563 5.56 6.659-6.915z"
    />
    <path d="M13 3.999c0 .552-.447.999-1 .999a.999.999 0 1 1 0-1.999c.553 0 1 .447 1 1" />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
